<template>
  <div id="updateSheetTagModal" class="create-post" uk-modal>
    <div
      class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small"
    >
      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">{{ title }}</h3>
        <button
          class="updateSheetTagClose uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
          type="button"
          uk-close
          uk-tooltip="title: Close ; pos: bottom ;offset:7"
        ></button>
      </div>

      <form v-on:submit.prevent="updateSheetTag"><div class="px-5 py-2">
            <multiselect
              v-model="currentTags"
              mode="tags"
              valueProp="id"
              label="name"
              trackBy="name"
              placeholder="Chọn tag trong danh sách"
              :closeOnSelect="true"
              :filterResults="true"
              :minChars="1"
              :resolveOnLoad="false"
              :delay="0"
              :searchable="true"
              :object="true"
              :options="allTags"
            >
              <template v-slot:option="{ option }">
                {{ option.name }}
              </template>

              <template v-slot:tag="{ option, handleTagRemove, disabled }">
                <div class="multiselect-tag">
                  {{ option.name }}
                  <span
                    v-if="!disabled"
                    class="multiselect-tag-remove"
                    @mousedown.prevent="handleTagRemove(option, $event)"
                  >
                    <span class="multiselect-tag-remove-icon"></span>
                  </span>
                </div>
              </template>
            </multiselect>

            <div class="mt-3">
              <div class="keyword-input-container shadow-none">
                <input
                  @keyup.enter="createTags"
                  v-model="tag_name"
                  type="text"
                  class="keyword-input with-border"
                  placeholder="Hoặc tạo tag mới, cách nhau bởi dấu phẩy (,)"
                />
                <button
                  @click="createTags"
                  type="button"
                  class="keyword-input-button ripple-effect"
                >
                  <i class="icon-feather-plus"></i>
                </button>
              </div>
            </div>

            <div data-simplebar style="height: 250px">
              <tag-cloud-select v-on:selectTag="selectTag" :tags="allTags"/>
            </div>
          </div>
       
        <div class="flex items-center w-full justify-center border-t p-3">
          <button
            type="submit"
            class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold"
          >
            Cập nhật
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<style>
input.multiselect-tags-search {
  height: auto !important;
}
</style>

<script>
import ApiService from "../core/services/api.service";
import TagCloudSelect from "./TagCloudSelect";

export default {
  name: "UpdateTagModal",
  components: { TagCloudSelect },
  props: {
    tags: Object,
    title: String
  },
  data() {
    return {
      tagIds: [],
      allTags: [],
      currentTags: [],
      tag_name: ""
    };
  },
  methods: {
    updateSheetTag() {
      this.$emit("updateDone", this.tagIds);
      window.$(".updateSheetTagClose").click();
    },
    loadAllTags() {
      let query = `query {
        tags(first:1000, orderBy: [{column: "id", order:DESC}]) {
          data {
            id
            name
          }
        }
      }`;

      ApiService.graphql(query)
        .then(({ data }) => {
          if (data.data && data.data.tags && data.data.tags.data) {
            this.allTags = data.data.tags.data;
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    selectTag(tag) {
      var existedTag = this.currentTags.filter((_tag) => {
        return tag.id == _tag.id;
      });

      if (!existedTag.length) {
        this.currentTags.push(tag);
      }
    },
    createTags() {
      if (!this.tag_name) {
        this.$toast.error("Bạn chưa nhập tên tag");
        return;
      }

      let query = `mutation($tags: String!) {
        createTags(tags: $tags) {
          id
          name
        }
      }`;

      ApiService.graphql(query, {tags: this.tag_name})
      .then(({data}) => {
        if (data.data && data.data.createTags) {
          this.tag_name = "";
          this.loadAllTags();
          data.data.createTags.forEach((tag) => {
            this.selectTag(tag);
          });
        } else {
          this.$toast.error(data.errors[0].message);
        }
      })
      .catch((response) => {
        this.$toast.error(response.message);
      });
    }
  },
  watch: {
    tags: {
      handler(newVal) {
        this.currentTags = JSON.parse(JSON.stringify(newVal));
      }
    },
    currentTags: {
      handler(newVal) {
        this.tagIds = [];
        newVal.forEach((tag) => {
          this.tagIds.push(tag.id);
        })
      },
      deep: true
    }
  },
  mounted() {
    this.loadAllTags();
  },
};
</script>
