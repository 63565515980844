<template>
  <div class="tags">
    <span class="cursor-pointer" :key="'tagCloudSelect' + tag.id" v-for="tag in tags" v-on:click="selectTag(tag)">
      <span class="keyword px-2">
        <span class="keyword-text">{{ tag.name }}</span>
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: "TagCloudSelect",
  props: {
    tags: Object
  },
  methods: {
    selectTag(tag) {
      this.$emit("selectTag", tag);
    }
  }
}
</script>
