<template>
  <tr class="">
    <td class="flex align-items-center">
      <div class="checkbox">
        <input type="checkbox" :id="'checkbox' + unique + sheet.id" v-model="isChecked"/>
        <label :for="'checkbox' + unique + sheet.id"><span class="checkbox-icon"></span></label>
      </div>
    </td>
    <td class="p-2 whitespace-nowrap font-semibold">
      <router-link :to="{name: 'AdminSheetEdit', params: {id: sheet.id}}">{{ sheet.title }}</router-link>
    </td>
    <td class="p-2 whitespace-nowrap font-semibold">
      {{ sheet.year }}
    </td>
    <td class="p-2 whitespace-nowrap font-semibold">
      {{ sheet.songs && sheet.songs.paginatorInfo ? sheet.songs.paginatorInfo.total : 0 }}
    </td>
    <td class="p-2 whitespace-nowrap font-semibold">
      <PeopleOfSong v-if="sheet.composers && sheet.composers.data" :people="sheet.composers.data" type="composer"/>
    </td>
    <td class="p-2 whitespace-nowrap" v-if="trashed === 'WITHOUT'">
      <router-link :to="{name: 'UserDetail', params: {id: sheet.uploader.id}}" v-if="sheet.uploader">
        {{ sheet.uploader.username }}
      </router-link>
    </td>
    <td class="p-2 whitespace-nowrap" v-if="trashed === 'ONLY'">
      <router-link :to="{name: 'UserDetail', params: {id: sheet.deletor.id}}" v-if="sheet.deletor">
        {{ sheet.deletor.username }}
      </router-link>
    </td>
    <td class="p-2 whitespace-nowrap" v-if="trashed === 'ONLY'">
      {{ sheet.delete_reason }}
    </td>
    <td class="p-2 whitespace-nowrap">
      {{ timeago(trashed === 'WITHOUT' ? sheet.created_at : sheet.deleted_at) }}
    </td>
    <td class="p-2 whitespace-nowrap">
      <div class="">
        <router-link class="mr-2" :to="{name: 'SheetDetail', params: {id: sheet.id, slug: sheet.slug}}">Xem</router-link>
        <router-link :to="{name: 'AdminSheetEdit', params: {id: sheet.id}}">Sửa</router-link>
      </div>
    </td>
  </tr>
</template>

<script>

import {
  timeago
} from "../../../core/services/utils.service";
import PeopleOfSong from "../../song/PeopleOfSong";

export default {
  name: "AdminSheetItem",
  props: {
    sheet: Object,
    trashed: String,
    unique: String
  },
  components: {PeopleOfSong},
  data() {
    return {
      isChecked: false
    }
  },
  methods: {
    timeago(x) {
      return timeago(x);
    }
  },
  created() {
    this.isChecked = this.sheet.is_checked;
  },
  watch: {
    "sheet.is_checked": {
      handler(newVal) {
        this.isChecked = newVal;
      },
      deep: true
    },
    isChecked: {
      handler(newVal) {
        this.$emit("handleCheckedSheet", this.sheet, newVal);
      }
    },
  }
}
</script>
