<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="w-full space-y-7">
        <div class="card p-3">
          <div class="flex items-center">
            <nav class="responsive-nav pl-2 is_ligh -mb-0.5 border-transparent">
              <ul>
                <li :class="{'active': trashed === 'WITHOUT'}"><a href="#" v-on:click="trashed='WITHOUT'">Tất cả<span>{{ allSheetsCount }}</span></a>
                </li>
                <li :class="{'active': trashed === 'ONLY'}"><a href="#" v-on:click="trashed='ONLY'">Bị xóa<span>{{ deletedSheetsCount }}</span></a>
                </li>
              </ul>
            </nav>
            <router-link class="flex items-center justify-center px-2 py-2 rounded-md bg-green-600 hover:bg-green-700 hover:text-white text-white space-x-1.5" :to="{name: 'AdminSheetAdd'}">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path></svg>
              <span class="whitespace-nowrap">Thêm mới</span>
            </router-link>
          </div>

          <form class="mt-4" v-on:submit.prevent="processBatchSheets">
            <div class="flex space-x-4">
              <input v-model="q" placeholder="Tìm kiếm" type="text" class="form-control shadow-none with-border"/>
              <input v-model="yearFrom" placeholder="Từ năm" type="text" class="form-control shadow-none with-border"/>
              <input v-model="yearTo" placeholder="Đến năm" type="text" class="form-control shadow-none with-border"/>
              <select v-model="action" class="btn-group bootstrap-select shadow-none with-border">
                <option value="">-- Thao tác --</option>
                <option value="delete">Xóa</option>
                <option value="restore">Khôi phục</option>
                <option value="destroy" v-if="isAdmin">Xóa vĩnh viễn</option>
                <option value="addTag">Gắn tag</option>
              </select>
              <select v-model="limit" class="btn-group bootstrap-select shadow-none with-border">
                <option value="10">-- Phân trang --</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
              </select>
              <button :disabled="!selectedSheetIds.length || !action" type="submit"
                      class="hover:bg-gray-300 bg-gray-200 rounded-md whitespace-nowrap px-4">Thực hiện
              </button>
            </div>
          </form>

          <div class="lg:mt-8 mt-2">
            <table class="w-full border-collapse">
              <thead>
              <tr class="border-b border-slate-200">
                <th class="flex align-items-center">
                  <div class="checkbox">
                    <input type="checkbox" id="checkbox" v-model="isCheckedAll"/>
                    <label for="checkbox"><span class="checkbox-icon"></span></label>
                  </div>
                </th>
                <th class="py-2 pr-2">Tiêu đề</th>
                <th class="py-2 pr-2">Năm</th>
                <th class="py-2 pr-2">Bài hát</th>
                <th class="py-2 pr-2">Sáng tác</th>
                <th class="py-2 pr-2" v-if="trashed === 'WITHOUT'">Đóng góp</th>
                <th class="py-2 pr-2" v-if="trashed === 'ONLY'">Người xóa</th>
                <th class="py-2 pr-2" v-if="trashed === 'ONLY'">Lý do xóa</th>
                <th class="py-2 pr-2" v-if="trashed === 'WITHOUT'">Ngày đăng</th>
                <th class="py-2 pr-2" v-if="trashed === 'ONLY'">Ngày xóa</th>
                <th class="py-2 pr-2"></th>
              </tr>
              </thead>
              <tbody class="divide-y" v-if="sheets.data && sheets.data.length">
              <sheet-item unique="sheets" :trashed="trashed" :sheet="sheet" v-on:handleCheckedSheet="handleCheckedSheet"
                          v-for="(sheet) in sheets.data" :key="trashed + sheet.id"/>
              </tbody>
            </table>

            <div class="mt-4">
              <v-pagination
                  class="justify-content-center"
                  v-if="sheets.paginatorInfo.lastPage > 1"
                  v-model="sheets.paginatorInfo.currentPage"
                  :pages="sheets.paginatorInfo.lastPage"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="loadAllSheets"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <update-tag-modal v-on:updateDone="handleUpdateDone" :tags="tags" title="Gắn tag hàng loạt"/>
  </div>
</template>

<script>
import ApiService from "../../../core/services/api.service";
import SheetItem from "./SheetItem";
import UpdateTagModal from "../../UpdateTagModal";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {canManageSheet, changePageTitle, isAdmin} from "../../../core/services/utils.service";

export default {
  name: "AdminSheetList",
  components: {SheetItem, VPagination, UpdateTagModal},
  data() {
    return {
      q: "",
      yearFrom: null,
      yearTo: null,
      limit: 10,
      action: "",
      isCheckedAll: false,
      trashed: "WITHOUT",
      sheets: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      allSheetsCount: 0,
      deletedSheetsCount: 0,
      selectedSheetIds: [],
      currentSheet: null,
      reason: "",
      tags: []
    }
  },
  methods: {
    loadAllSheets() {
      let where = {
        AND: []
      }

      let orderBy = [{column: "created_at", order: "DESC"}];

      if (this.q) {
        where.AND.push({column: "title", value: "%" + this.q + "%", operator: "LIKE"});
      }

      if (this.yearFrom) {
        where.AND.push({column: "year", value: parseInt(this.yearFrom), operator: "GTE"});
        orderBy = [{column: "year", order: "ASC"}];
      }

      if (this.yearTo) {
        where.AND.push({column: "year", value: parseInt(this.yearTo), operator: "LTE"});
        orderBy = [{column: "year", order: "ASC"}];
      }

      let query = `query($page: Int, $where: WhereConditions, $trashed: Trashed, $first: Int, $orderBy: [OrderByClause!]) {
        sheets(trashed: $trashed, first: $first, where: $where, page: $page, orderBy: $orderBy) {
          data {
            id
            title
            slug
            year
            delete_reason
            composers(first: 10) {
              data {
                id
                slug
                title
              }
            }
            songs(first: 100) {
              paginatorInfo {
                total
              }
            }
            created_at
            deleted_at
            uploader {
              id
              username
            }
            deletor {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {orderBy: orderBy, first: parseInt(this.limit), trashed: this.trashed, where: where, page: this.sheets.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.sheets) {
              this.sheets = data.data.sheets;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadAllSheetsCount() {
      let query = `query {
        sheets(first:1, page: 1) {
          paginatorInfo {
            total
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.sheets && data.data.sheets.paginatorInfo) {
              this.allSheetsCount = data.data.sheets.paginatorInfo.total;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadDeletedSheetsCount() {
      let query = `query {
        sheets(trashed: ONLY, first:1, page: 1) {
          paginatorInfo {
            total
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.sheets && data.data.sheets.paginatorInfo) {
              this.deletedSheetsCount = data.data.sheets.paginatorInfo.total;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    handleCheckedSheet(sheet, isChecked) {
      this.sheets.data.map((item) => {
        if (item.id === sheet.id) {
          item.is_checked = isChecked;
          return false;
        }
      })
    },
    processBatchSheets() {
      if (this.action === "delete") {
        this.deleteBatchSheets();
      } else if (this.action === "restore") {
        this.restoreBatchSheets();
      } else if (this.action === "destroy") {
        this.destroyBatchSheets();
      } else if (this.action === "addTag") {
        window.$('#updateSheetTagModal').addClass('uk-open').show();
      }
    },
    deleteBatchSheets() {
      if (confirm("Bạn có chắc muốn thực hiện thao tác này?")) {
        let query = `mutation($ids: [ID]!) {
          deleteBatchSheets(ids: $ids)
        }`;

        ApiService.graphql(query, {ids: this.selectedSheetIds})
            .then(({data}) => {
              if (data.data && data.data.deleteBatchSheets) {
                this.reRender();
              }
            })
            .catch((response) => {
              console.log(response);
            });
      }
    },
    restoreBatchSheets() {
      if (confirm("Bạn có chắc muốn thực hiện thao tác này?")) {
        let query = `mutation($ids: [ID]!) {
          restoreBatchSheets(ids: $ids)
        }`;

        ApiService.graphql(query, {ids: this.selectedSheetIds})
            .then(({data}) => {
              if (data.data && data.data.restoreBatchSheets) {
                this.reRender();
              }
            })
            .catch((response) => {
              console.log(response);
            });
      }
    },
    destroyBatchSheets() {
      if (confirm("Bạn có chắc muốn thực hiện thao tác này?")) {
        let query = `mutation($ids: [ID]!) {
          destroyBatchSheets(ids: $ids)
        }`;

        ApiService.graphql(query, {ids: this.selectedSheetIds})
            .then(({data}) => {
              if (data.data && data.data.destroyBatchSheets) {
                this.reRender();
              }
            })
            .catch((response) => {
              console.log(response);
            });
      }
    },
    reRender() {
      this.isCheckedAll = false;
      this.selectedSheetIds = [];
      this.loadAllSheets();
      this.loadAllSheetsCount();
      this.loadDeletedSheetsCount();
    },
    handleUpdateDone(tagIds) {
      let query = `mutation($sheet_ids: [ID]!, $tag_ids: [ID]!) {
        addBatchSheetTag(sheet_ids: $sheet_ids, tag_ids: $tag_ids)
      }`;

      ApiService.graphql(query, { sheet_ids: this.selectedSheetIds, tag_ids: tagIds })
        .then(({ data }) => {
          if (data.data && data.data.addBatchSheetTag) {
            this.$toast.success("Cập nhật thành công");
            this.tags = [];
            this.reRender();
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    }
  },
  mounted() {
    if (!canManageSheet()) {
      this.$router.push({name: "Home"});
      return;
    }
    this.reRender();
    changePageTitle("Quản lý sheet");
  },
  computed: {
    isAdmin() {
      return isAdmin();
    }
  },
  watch: {
    q: {
      handler() {
        this.isCheckedAll = false;
        this.sheets.paginatorInfo.currentPage = 1;
        this.loadAllSheets();
      }
    },
    yearFrom: {
      handler() {
        this.isCheckedAll = false;
        this.sheets.paginatorInfo.currentPage = 1;
        this.loadAllSheets();
      }
    },
    yearTo: {
      handler() {
        this.isCheckedAll = false;
        this.sheets.paginatorInfo.currentPage = 1;
        this.loadAllSheets();
      }
    },
    limit: {
      handler() {
        this.isCheckedAll = false;
        this.sheets.paginatorInfo.currentPage = 1;
        this.loadAllSheets();
      }
    },
    isCheckedAll: {
      handler(newVal) {
        this.sheets.data.map((sheet) => {
          sheet.is_checked = newVal;
        });
      }
    },
    "sheets.data": {
      handler(newVal) {
        this.selectedSheetIds = [];
        newVal.map((sheet) => {
          if (sheet.is_checked) {
            this.selectedSheetIds.push(sheet.id);
          }
        });
      },
      deep: true
    },
    trashed: {
      handler() {
        this.sheets.paginatorInfo.currentPage = 1;
        this.reRender();
      },
    }
  }
}
</script>
